<template>
  <v-container fluid class="fill-height">
    <v-row class="ma-0 d-flex flex-column justify-center align-center">
      <h1 class="mt-6 font-weight-light mb-5">
        {{ $ml.get("request_password_change_text") }}
      </h1>
      <p class="font-weight-light mb-5">
        {{ $ml.get("request_password_change_description") }}
      </p>
      <v-card
        width="450"
        color="old-password-reset"
        class="rounded-sm pa-10 mt-6"
        outlined
      >
        <v-form v-model="validation" style="width: 100%">
          <v-row
            class="ma-0 d-flex flex-column"
            v-for="item in resetForm"
            :key="item.key"
          >
            <label :for="item.key" class="mb-1"
              >{{ $ml.get(item.label) }}:</label
            >
            <v-text-field
              v-if="item.type === 'email'"
              :id="item.key"
              v-model="requestPayload[item.key]"
              :type="item.type"
              :disabled="item.disabled || loading"
              :readonly="item.readonly"
              outlined
              :rules="item.rules ? item.rules : []"
              :background-color="item.disabled ? '#ECF0F1' : 'white'"
              max-height="25"
              :hint="item.type === 'email' ? $ml.get('user_email_hint') : ''"
              :persistent-hint="item.type === 'email'"
              :class="item.type === 'email' ? 'mb-4' : ''"
            />
            <v-textarea
              v-else
              :id="item.key"
              v-model="requestPayload[item.key]"
              :type="item.type"
              :disabled="item.disabled || loading"
              :readonly="item.readonly"
              outlined
              :rules="item.rules ? item.rules : []"
              :background-color="item.disabled ? '#ECF0F1' : 'white'"
              max-height="25"
            />
          </v-row>
          <v-btn
            :loading="loading"
            :disabled="!validation || requestSent"
            block
            color="blue"
            x-large
            elevation="0"
            class="text-none text-body-1 font-weight-bold white--text"
            @click="requestPasswordChange()"
          >
            {{ $ml.get("request_password_change_text") }}
          </v-btn>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import { rulesBuilder } from "@/helpers/utils";

export default {
  name: "RequestPasswordChange",
  data() {
    return {
      validation: null,
      loading: false,
      requestPayload: {
        email: "",
        comment: "",
      },
      errorCode: "",
      errorCodes: {
        USER_NOT_FOUND: "USER_NOT_FOUND",
        SERVICE_UNAVAILABLE: "service_unavailable",
        429: "REQUEST_LIMIT_EXCEEDED",
        500: "error_unknown",
      },
      emailReadOnly: false,
      requestSent: false,
      rules: {
        min_len: (v) => v.length >= 10 || this.$ml.get("min_len_is_10"),
      },
      oauth: undefined,
    };
  },
  computed: {
    utilsRules() {
      return rulesBuilder(this);
    },
    resetForm() {
      return [
        {
          type: "email",
          key: "email",
          label: "email",
          disabled: this.requestSent,
          readonly: this.emailReadOnly,
          rules: [this.utilsRules.validateEmail],
        },
        {
          type: "comment",
          key: "comment",
          label: "comment",
          disabled: this.requestSent,
          rules: [this.rules.min_len],
        },
      ];
    },
  },
  methods: {
    ...mapActions(["setSnackbarDefault"]),

    ...mapMutations(["setLanguage"]),

    changeLanguage(lang) {
      this.$ml.change(lang);
      this.setLanguage(lang);
    },

    async requestPasswordChange() {
      this.loading = true;
      const url = `/users/request-password-change?oauth=${this.oauth}`;
      await this.$axios
        .post(url, this.requestPayload)
        .then(() => {
          this.setSnackbarDefault({
            message: "success_request_password_change",
            color: "success",
          });
          this.requestSent = true;
        })
        .catch((error) => {
          if (error.response.status !== 500) {
            this.errorCode =
              error.response.status === 429 ? 429 : error.response.data.code;
          } else {
            this.errorCode = 500;
          }
          this.setSnackbarDefault({
            message: this.errorCodes[this.errorCode],
            color: "error",
          });
        })
        .finally(() => (this.loading = false));
    },
  },
  created() {
    const oauth =
      this.$route.query.oauth || localStorage.getItem("oauth") || "google";

    localStorage.setItem("oauth", oauth);

    this.oauth = oauth;

    if (this.$route.query.language) {
      this.changeLanguage(this.$route.query.language);
    }

    if (this.$route.query.email) {
      this.requestPayload.email = this.$route.query.email;
      this.emailReadOnly = true;
      this.$router.replace({ query: null });
    }
  },
};
</script>
<style scoped>
.old-password-reset {
  background: #f7f7f7 !important;
}
</style>
